<template>
  <div class="w-full pt-5">
    <div class="flex w-full">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Performance Appraisals
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <template>
      <div class="w-full p-5 flex mt-1 flex-col">
        <Tabs
          class="mt-6 mx-3"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
        <div style="height: 100%" v-if="isLoading">
          <Loader size="xxs" />
        </div>
        <div v-else class="w-full flex flex-col">
          <TStatusReports v-if="activeTab === 'Status Reports'" />
          <TMyAppraisals v-if="activeTab === 'My Appraisals'" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Loader from "@scelloo/cloudenly-ui/src/components/loader";
import TStatusReports from "./Tabs/TStatusReports";
import TMyAppraisals from "./Tabs/TMyAppraisals";

export default {
  name: "EssPerformanceAppraisals",
  components: {
    Tabs,
    Breadcrumb,
    Loader,
    TStatusReports,
    TMyAppraisals,
  },
  data() {
    return {
      openModal: false,
      isLoading: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisals",
          href: "performance-appraisls",
          id: "PerformanceAppraisals",
        },
      ],
      tabsItems: ["Status Reports", "My Appraisals"],
      activeTab: 'Status Reports',
      reportingTo: false,
    };
  },

  methods: {
    getUserReportingTo() {
      const payload = { id: this.$AuthUser.id };
      this.$_getOneEmployeeAll(payload).then((response) => {
        this.reportingTo = !!response.data.userReportingTo;

        this.getUserDirectReports(this.reportingTo);
      });
    },

    getUserDirectReports(reportingTo) {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((result) => {
        if (result.data.directReport.length === 0 && reportingTo) {
          this.manager = false;
          this.tabsItems.push("Status Reports", "My Appraisals");
          this.activeTab = this.$route.query.activeTab
            ? this.$route.query.activeTab
            : "Status Reports";
        } else if (result.data.directReport.length > 0) {
          this.manager = true;
          if (!reportingTo) {
            this.tabsItems.push(
              "Direct Reports",
              "Appraisal Scorecard"
            );
            this.activeTab = "Direct Reports";
          } else {
            this.tabsItems.push(
              "Status Reports",
              "My Appraisals",
              "Direct Reports",
              "Appraisal Scorecard"
            );
            this.activeTab = this.$route.query.activeTab
              ? this.$route.query.activeTab
              : "Status Reports";
          }
        }
          this.isLoading = false;
      });
    },
  },

  mounted() {
    //this.getUserReportingTo();
  },
};
</script>